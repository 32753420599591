import { Page, Text, Document, StyleSheet, View } from '@react-pdf/renderer';
import { Report } from './AccountantReport';
import { GetSentReportResponseBodyDataContentCountry, GetSentReportResponseBodyDataCurrencyContent } from '../../../../../types';
import { useState } from 'react';

const styles = StyleSheet.create({
    report: {
        display: 'flex',
        flexDirection: 'column',
    },
    "report-type": {
        alignSelf: 'center',
        marginBottom: '30px'
    },
    'report-currency-items': {
        gap: '20px',
        fontSize: '12px'
    },
    'report-currency': {
        margin: '3px',
        border: '2px solid black',
        padding: '3px',
        fontSize: '12px'
    },
    'report-currency-title': {
        marginBottom: '10px',
        fontSize: '12px'
    },
    'report-currency-total': {
        marginTop: '5px',
        alignItems: 'flex-end',
        fontSize: '12px'
    },
    'report-currency-total-result': {
        fontSize: '12px',
        fontWeight: 'bold',
    },
    'report-products': {
        flexDirection: 'column',
        fontSize: '12px',
        marginLeft: '20px'
    },'report-product-filter': {
        flexDirection: 'row',
        fontSize: '12px',
        gap: '10px',
        marginBottom: '5px'
    },
    'report-product': {
        flexDirection: 'row',
        fontSize: '12px',
        gap: '10px'
    },
    'report-product-id': {
        width: '10%',
        justifyContent: 'center',
        fontSize: '12px'
    },
    'report-product-name': {
        width: '20%',
        justifyContent: 'center',
        fontSize: '12px'
    },
    'report-product-sku': {
        width: '20%',
        justifyContent: 'center',
        fontSize: '12px'
    },
    'report-product-quantity': {
        width: '10%',
        justifyContent: 'center',
        fontSize: '12px'
    },
    'report-product-warehouse-price': {
        width: '15%',
        justifyContent: 'center',
        fontSize: '12px'
    },
    'report-product-total-sell-price': {
        width: '15%',
        justifyContent: 'center',
        fontSize: '12px'
    },
    'report-countries': {
        marginLeft: '20px',
        gap: '10px'
    }
});

const PdfAccountentReport: React.FC<Report> = ({sentType, report, curDate}) => {
    const getTotalSellPrice = (productsReport: GetSentReportResponseBodyDataCurrencyContent): number => {
        return Object.values(productsReport).reduce((prev, cur) => prev + cur.totalSellPrice, 0);
    }

    const getTotalSellPerCurrency = (countryReport: GetSentReportResponseBodyDataContentCountry): number => {
        return Object.values(countryReport).reduce( (prev, cur) => prev + getTotalSellPrice(cur), 0);
    }

    const genrateReportProducts = (countryReport: GetSentReportResponseBodyDataContentCountry, currency: string) => {
        return (
            <View style={styles["report-countries"]}>
                {Object.entries(countryReport).map(([country, productReport], countryIndex) => {
                    const totalCountrySellPrice = getTotalSellPrice(productReport);
                    
                    return (
                        <View key={countryIndex}>
                            <View>
                                <Text>{country} ( Total: {totalCountrySellPrice} {currency} )</Text>
                            </View>
                            <View style={styles["report-products"]}>
                                <View style={styles["report-product-filter"]}>
                                    <Text style={styles["report-product-id"]}>ID</Text>
                                    <Text style={styles["report-product-name"]}>NAME</Text>
                                    <Text style={styles["report-product-sku"]}>SKU</Text>
                                    <Text style={styles["report-product-quantity"]}>QUANTITY</Text>
                                    <Text style={styles["report-product-warehouse-price"]}>WAREHOUSE PRICE</Text>
                                    <Text style={styles["report-product-total-sell-price"]}>TOTAL SELL PRICE</Text>
                                </View>
                                {Object.entries(productReport).map( ([productId, productData], productIndex) => (
                                    <View key={productIndex} style={styles["report-product"]}>
                                        <Text style={styles["report-product-id"]}>{productId}</Text>
                                        <Text style={styles["report-product-name"]}>{productData.name}</Text>
                                        <Text style={styles["report-product-sku"]}>{productData.sku}</Text>
                                        <Text style={styles["report-product-quantity"]}>{productData.quantity}</Text>
                                        <Text style={styles["report-product-warehouse-price"]}>{productData.warehousePrice} PLN</Text>
                                        <Text style={styles["report-product-total-sell-price"]}>{productData.totalSellPrice} {currency}</Text>
                                    </View>
                                ))}
                            </View>
                        </View>
                    )
                })}

            </View>
        )
    }

    const Currency = (countryReport: GetSentReportResponseBodyDataContentCountry, currency: string, currencyIndex: number) => {
        const currencyTotal = getTotalSellPerCurrency(countryReport);

        return (
            <View key={currencyIndex} style={styles["report-currency"]}>
                <Text style={styles["report-currency-title"]}>{currency} ( Total: {currencyTotal.toFixed(2)} {currency} )</Text>
                {genrateReportProducts(countryReport, currency)}
            </View>
        )
    }


    return (
        <Document>
            <Page size='A3'>
                <View style={styles.report}>
                    <Text style={styles["report-type"]}>Report type - {sentType.toUpperCase()} Accountant ({curDate})</Text>
                    <View style={styles["report-currency-items"]}>
                        {Object.entries(report).map( ([currency, countryReport], index) => (
                            <>
                                {Currency(countryReport, currency, index)}
                            </>
                        ))}
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default PdfAccountentReport;