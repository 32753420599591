import { useState, useEffect } from "react";
import styles from './styles.module.css';

interface Loading {
    /**seconds */
    frequency?: number,
    maxNumberOfDots?: number,
    classNames?: string[]
}

const Loading: React.FC<Loading> = ({frequency = 1, maxNumberOfDots = 3, classNames}) => {
    let [numberOfDots, setNumberOfDots] = useState<number>(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setNumberOfDots((prevNumberOfDots: number) => (
                prevNumberOfDots < maxNumberOfDots ? ++prevNumberOfDots : 1
            ))
        }, 1000 * frequency);

        return () => {
            clearInterval(interval);
        }
    }, [maxNumberOfDots, frequency]);

    const generateDots = (numberOfDots: number) => {
        return (
            <span className={`${styles['loading-dots']} ${classNames?.join(' ')}`}>{Array.from({length: numberOfDots}, () => '.')}</span>
        )
    }

    return (
        <div className={styles.loading}><span className={styles['loading-main-text']}>Loading</span>{generateDots(numberOfDots)}</div>
    )
}

export default Loading;