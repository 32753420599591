import Messages from "../pages/Messages";

const Footer = () => {
    return (
        <footer className="w-full min-h-[10vh]">
            <Messages />
        </footer>
    )
}

export default Footer;