class DateTime {
    static addDaysToUnix(unix: number, countOfDays: number) {
        return unix + ((24 * 60 * 60) * countOfDays);
    }

    static timeMsToDayMs(ms: number) {
        return Number.parseInt((ms / 1000).toFixed(0))
    }

    static updateSecondsWithHours(utcSec: number, hours: number) {
        return utcSec + (hours * 60 * 60);
    }
}

export default DateTime;