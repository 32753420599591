import { Page, Text, Document, StyleSheet, View } from '@react-pdf/renderer';
import { Report } from './WarehouseReport';
import { GetSentReportResponseBodyDataCurrencyContent, WarehouseReportData, WarehouseReportProduct, WarehouseReportProducts } from '../../../../../types';
import { getOrdersTotal } from '../../../../../helpers/warehouse.helper';

const styles = StyleSheet.create({
    report: {
        display: 'flex',
        flexDirection: 'column',
    },
    "report-type": {
        alignSelf: 'center',
        marginBottom: '30px'
    },
    'report-currency-items': {
        gap: '20px',
        borderBottom: '2px solid black'
    },
    'report-currency': {
        margin: '3px',
        border: '2px solid black',
        padding: '3px'
    },
    'report-currency-title': {
        marginBottom: '10px'
    },
    'report-currency-total': {
        marginTop: '5px',
        alignItems: 'flex-end'
    },
    'report-currency-total-result': {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    'report-products': {
        flexDirection: 'column'
    },'report-product-filter': {
        flexDirection: 'row',
        fontSize: '12px',
        gap: '10px',
        marginBottom: '5px'
    },
    'report-product': {
        flexDirection: 'row',
        fontSize: '12px',
        gap: '10px'
    },
    'report-product-id': {
        width: '10%',
        justifyContent: 'center'
    },
    'report-product-name': {
        width: '20%',
        justifyContent: 'center'
    },
    'report-product-sku': {
        width: '20%',
        justifyContent: 'center'
    },
    'report-product-quantity': {
        width: '10%',
        justifyContent: 'center'
    },
    'report-product-warehouse-price': {
        width: '15%',
        justifyContent: 'center'
    },
    'report-product-total-sell-price': {
        width: '15%',
        justifyContent: 'center'
    },
    'report-orders-list': {
        marginTop: '10px'
    },
    'warehouse-report-product-filter': {
        fontWeight: 'bold',
        flexDirection: 'row'
    },
    'warehouse-report-product-id': {
        width: '15%',
    },
    'warehouse-report-product-quantity': {
        width: '15%',
    },
    'warehouse-report-product-sku': {
        width: '25%',
    },
    'warehouse-report-product-name': {
        width: '45%',
        overflow: 'hidden',
        maxHeight: '100%'
    },
    'warehouse-report-product': {
        flexDirection: 'row',
        display: 'flex',
        gap: '5px'
    },
    'warehouse-report-products-list': {
        padding: '20px',
        flexDirection: 'column',
        gap: '5px',
        fontSize: '12px',
        borderBottom: '2px solde black',
    },
    'warehouse-report-products-list-title': {
        marginBottom: '10px',
        alignSelf: 'center',
        fontSize: '18px'
    },
    'warehouse-report-products-list-orders': {
        fontSize: '12px'
    }
});

const PdfWarehouseReport: React.FC<Report> = ({sentType, report, curDate}) => {
    const genrateReportOrders = (productsReport: WarehouseReportData) => {
        return (
            <View style={styles["report-products"]}>
                {Object.entries(productsReport.orders).map(([country, ordersId], index) => (
                    <View key={index}>
                        <Text>{country} - {ordersId.length}</Text>
                    </View>
                ))}
                <Text>Total: {getOrdersTotal(report)}</Text>
            </View>
        )
    }

    const generateArrayOfOrderIds = (report: WarehouseReportData) => {
        return Object.values(report.orders).reduce( (prev, cur) => [...prev, ...cur], [])
    }

    const generateProducts = (products: WarehouseReportProducts) => {
        return (
            <>
                <View style={styles["warehouse-report-product-filter"]}>
                    <Text style={styles["warehouse-report-product-id"]}>ID</Text>
                    <Text style={styles["warehouse-report-product-name"]}>NAME</Text>
                    <Text style={styles["warehouse-report-product-sku"]}>SKU</Text>
                    <Text style={styles["warehouse-report-product-quantity"]}>QUANTITY</Text>
                </View>
                {Object.entries(products).map(([productId, product]) => (
                    <View key={productId} style={styles["warehouse-report-product"]}>
                        <Text style={styles["warehouse-report-product-id"]}>{productId}</Text>
                        <Text style={styles["warehouse-report-product-name"]}>{product.name}</Text>
                        <Text style={styles["warehouse-report-product-sku"]}>{product.sku}</Text>
                        <Text style={styles["warehouse-report-product-quantity"]}>{product.quanitity}</Text>
                    </View>
                ))}
            </>
        )
    }


    return (
        <Document>
            <Page size='A3'>
                <View style={styles.report}>
                    <Text style={styles["report-type"]}>Report type - {sentType.toUpperCase()} Warehouse ({curDate})</Text>
                    <View style={styles["report-currency-items"]}>
                        {genrateReportOrders(report)}
                    </View>
                    <View style={styles["warehouse-report-products-list"]}>
                        <Text style={styles["warehouse-report-products-list-title"]}>Products List</Text>
                        {generateProducts(report.products)}
                    </View>
                    <View style={styles["report-orders-list"]}>
                        <Text style={styles["warehouse-report-products-list-title"]}>Orders list</Text>
                        <Text style={styles["warehouse-report-products-list-orders"]}>{generateArrayOfOrderIds(report).join(', ')}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default PdfWarehouseReport;