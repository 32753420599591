import React, { useState } from "react"
import { DateFromToInput } from "../../components"
import Btn from "../../components/Btns/Btn";
import { fetchBaselinkerOrders } from "../../api/backBaselinkerApi";
import { useAppDispatch } from "../../store/hooks";
import { addMessageWithTimeOut } from "../../store/features/comunicatorSlice";
import DateTime from "../../helpers/DateTime";

const BaselinkerOrdersStatictics = () => {
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    const dispatch = useAppDispatch();

    const isDateSet = (dateFrom: string, dateTo: string) => {
        if(dateFrom && dateTo) return true;
        return false;
    }

    const onLoadOrdersClick = async () => {
        if(isDateSet(dateFrom, dateTo)) {
            const responseData = await fetchBaselinkerOrders({
                dateFrom: DateTime.timeMsToDayMs(Date.parse(dateFrom)) - 60 * 60 * 2,
                dateTo: DateTime.addDaysToUnix(DateTime.timeMsToDayMs(Date.parse(dateTo)), 1),
                severalFolder: true
            })
            dispatch(addMessageWithTimeOut(responseData.message, responseData.error));
        } else {
            dispatch(addMessageWithTimeOut('Please set date first', true));
        }
    }

    return (
        <section>
            <DateFromToInput setDateFrom={setDateFrom} setDateTo={setDateTo} dateFrom={dateFrom} dateTo={dateTo}/>
            <div>
                <div className="flex flex-row justify-center mt-[20px]">
                    <Btn value="Load Orders" onClick={onLoadOrdersClick}/>
                </div>
            </div>
        </section>
    )
}

export default BaselinkerOrdersStatictics;