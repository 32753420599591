import { useEffect, useState } from "react";
import { DateFromToInput } from "../../../components";
import { getCostsBaselinkerData } from "../../../api/backBaselinkerApi";
import DateTime from "../../../helpers/DateTime";
import Btn from "../../../components/Btns/Btn";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addMessageWithTimeOut } from "../../../store/features/comunicatorSlice";
import { CostBaselinkerReportOrder } from "../../../types";
import CostReport from "./CostReport";
import { fetchSkuSettings } from "../../../store/features/skuSettingsSlice";
import {fetchOroginBaselinkerData} from '../../../store/features/costReportDbOriginDataSlice';

const BaselinkerCostsReport = () => {
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    const dispatch = useAppDispatch();

    const onRefreshSettings = async () => {
        dispatch(fetchSkuSettings());
    }


    const onCreatePaymentReport = async () => {
        if(dateFrom && dateTo) {
            try {
                dispatch(addMessageWithTimeOut('Loading...', false));

                dispatch(fetchOroginBaselinkerData({
                    dateFrom: DateTime.timeMsToDayMs(Date.parse(dateFrom)) - 60 * 60 * 2,
                    dateTo: DateTime.addDaysToUnix(DateTime.timeMsToDayMs(Date.parse(dateTo)), 1) - 60 * 60 * 2
                }));

                onRefreshSettings()
            } catch (e: any){
                dispatch(addMessageWithTimeOut('Server error', true));
            }
        } else {
            dispatch(addMessageWithTimeOut('Please fill each field', true));
        }
    }


    return (
        <>
            <div className="flex flex-col items-center w-[100%] gap-[10px]">
                <DateFromToInput dateFrom={dateFrom} dateTo={dateTo} setDateFrom={setDateFrom} setDateTo={setDateTo} />
                <div className="flex flex-row gap-[10px]">
                    {/* <CheckBox value={includeCorrecting} setValue={setIncludeCorrecting} labelName="Include correcting?"/> */}
                    <Btn onClick={onCreatePaymentReport} value="Get Report" />
                    {/* <InputText setValue={setNewSheetName} value={newSheetName} placeholder='Input new sheet name'/>
                    <Btn onClick={onCreatePaymentReport} value="Generate report"/> */}
                </div>
                <CostReport />
                <a target="_blank" rel="noreferrer" href="https://docs.google.com/spreadsheets/d/1e4lpwkiWWvKUllyIW4Yhsidv0Gkg8T55YlmIGxt0o1E/edit#gid=0" className="font-bold underline decoration-black">Google Sheet Link</a>
            </div>
        </>
    )
}

export default BaselinkerCostsReport;