import Btn from "./Btns/Btn";
import DateInput from "./DateInput";

interface IDateFromInput {
    setDateFrom: Function,
    setDateTo: Function,
    dateFrom: string,
    dateTo: string
}

const DateFromToInput: React.FC<IDateFromInput> = ({setDateFrom, setDateTo, dateFrom, dateTo}) => {

    const ifDateExist = (dateFrom: string, dateTo: string) => dateFrom || dateTo ? true : false;
    const onResetClick = () => {
        setDateFrom('');
        setDateTo('');
    }

    return (
        <div>
            <div className="flex flex-col justify-center gap-[50px] md:flex-row md:gap-[200px] ">
                <DateInput
                    lableText="Set date from"
                    setDateFunc={setDateFrom}
                    lableId="date_input_1"
                    value={dateFrom}
                />
                {ifDateExist(dateFrom, dateTo) && <Btn onClick={onResetClick} value="Reset" />}
                <DateInput
                    lableText="Set date to"
                    setDateFunc={setDateTo}
                    lableId="date_input_2"
                    value={dateTo}
                />
            </div>
            {}
        </div>
    )
}

export default DateFromToInput;