import axios from "axios";
import { BackResponce, IskladApiSettings } from "../types";


const baselinkersUrl = 'admin/api/v1/isklad';
const baseURL = process.env.NODE_ENV === 'production' ? `/${baselinkersUrl}` : `http://localhost:5002/${baselinkersUrl}`;

const api = axios.create({baseURL: baseURL});

export async function getIskladSettings(): Promise<BackResponce<IskladApiSettings[]>> {
    return (await api.get('/get/api/data')).data;
}