interface IAdInfo {
    accountNumber: string,
    campaignsQuantity: number
}

const AdInfo: React.FC<IAdInfo> = ({accountNumber, campaignsQuantity}) => {
    return (
        <div className=' border-slate-950 border-2 text-[12px]'>
            <div className='flex flex-row'> {/*Head*/}
                <div className="">Account Number: {accountNumber}</div>
                <div className="">Campaigns quantity: {campaignsQuantity}</div>
            </div>
        </div>
    )
}

export default AdInfo;