import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import { CostReportFinalData } from '../../types';

type CostReportState = {
    productsData: CostReportFinalData[]
}

const initialState: CostReportState = {
    productsData: []
}

const costReportSlice = createSlice({
    name: 'skuSettings',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<CostReportFinalData>) => {
            const productData = state.productsData.find(  stateData => {
                return stateData.id === action.payload.id ? true : false
            })

            if(!productData) {
                state.productsData.push(action.payload);
            }
        },
        remove: (state, action: PayloadAction<string>) => {
            const newArray = state.productsData.filter( stateData => stateData.id !== action.payload);
            state.productsData = newArray;
        }
    }
});

export const {add, remove} = costReportSlice.actions;

export default costReportSlice.reducer;
