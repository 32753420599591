import axios from "axios";
import { AddOrdersToIskaldReqBody, BackResponce, BaseLoadData, BaselinkerInventory, BaselinkerOrder, GetBaselinkerOrdersRequest, GetBaselinkerSentReportRequest, GetBaselinkerStatusesRequest, GetBaselinkerWarehousesRequest, GetSentReportResponseBodyData, SendCostReportToGoogleReques, TransferBlOrdersToIskladResponse } from "../types";

const backAdminUrl = 'admin/api/v1';
const baseURL = process.env.NODE_ENV === 'production' ? `/${backAdminUrl}` : `http://localhost:5002/${backAdminUrl}`;

const api = axios.create({baseURL: baseURL});

export async function sendBaselinkerOrdersFilesToBack(data: FormData): Promise<BackResponce<any>> {
    return (await api.post('/globalDBConfig/addBaselinkerOrdersToDBWithFiles', data)).data;
}

export async function getBaselinkerReportBaseData(body: GetBaselinkerStatusesRequest): Promise<BackResponce<BaseLoadData>> {
    return (await api.post('/baselinker/get/sent-base-data', body)).data;
}

export async function getBaselinkerInventories(body: GetBaselinkerWarehousesRequest): Promise<BackResponce<BaselinkerInventory[]>> {
    return (await api.post('/baselinker/get/inventories', body)).data;
}

export async function getBaselinkerSentReport(body: GetBaselinkerSentReportRequest): Promise<BackResponce<GetSentReportResponseBodyData>> {
    return (await api.post('/baselinker/get/report', body)).data;
}

export async function getBaselinkerOrders(body: GetBaselinkerOrdersRequest): Promise<BackResponce<BaselinkerOrder[]>> {
    return (await api.post('/baselinker/get/orders', body)).data;
}

export async function transferOrdersToIsklad(body: AddOrdersToIskaldReqBody): Promise<BackResponce<TransferBlOrdersToIskladResponse>> {
    return (await api.post('/baselinker/orders/transfer/to/isklad', body)).data;
}