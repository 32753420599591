import styles from './styles.module.css';

type MultipleRadio<T> = {
    btns: {title: string, value: T, uniqId: string}[],
    setValue: (value: T) => void 
    uniqName: string,
    selectedValue: T,
    gap?: number
}

const MultipleRadio = <T,>({btns, uniqName, selectedValue, setValue, gap=5}: MultipleRadio<T>) => {
    return (
        <div className={styles.container} style={{gap: `${gap}px`}}>
            {btns.map( (btn) => {
            return (
                <div onClick={() => setValue(btn.value)} className={`${btn.value === selectedValue && styles.active} ${styles.btn}`}>  
                    <label htmlFor={btn.uniqId}>{btn.title}</label>
                    <input style={{display: 'none'}} checked={selectedValue === btn.value} id={btn.uniqId} type="radio"  name={uniqName} value={btn.value as string} ></input>
                </div>
            )})}
        </div>
    )
}

export default MultipleRadio;