import { NavLink, Outlet } from "react-router-dom";
import styles from './baselinker.module.css';

const Baselinker = () => {
    const generateRouteOptions = (): JSX.Element => {
        return (
            <nav className={`${styles['baselinker-nav']}`}>
                <NavLink className={({isActive}) => (`${styles['baselinker-nav-item']} ${isActive ? styles['active-nav'] : ''}`)} to='transfer-orders-to-isklad'>Transfer orders to Isklad</NavLink>
                <NavLink className={({isActive}) => (`${styles['baselinker-nav-item']} ${isActive ? styles['active-nav'] : ''}`)} to='payment-report'>Generate Payment Report</NavLink>
                <NavLink className={({isActive}) => (`${styles['baselinker-nav-item']} ${isActive ? styles['active-nav'] : ''}`)} to='get-product-costs'>Generate Products Costs Report</NavLink>
                <NavLink className={({isActive}) => (`${styles['baselinker-nav-item']} ${isActive ? styles['active-nav'] : ''}`)} to='get-sent-report'>Generate sent report</NavLink>
            </nav>
        )
    }

    return (
        <div className="flex flex-row h-[100%] absolute">
            <section className="border-r-black border-r-2 flex flex-col items-center w-[15vw]">
                <p className="text-[20px] font-bold mx-[20px]">Select Task</p>
                <div id="selectTask" className="flex flex-col mx-[20px]">
                    {generateRouteOptions()}
                </div>
            </section>
            <section className="w-[85vw]">
                <Outlet />
            </section>
        </div>
    )
}

export default Baselinker;