import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {SkuSettings } from '../../types';
import {getAllSkuSettings} from '../../api/backBaselinkerApi';

type SkuSettingsState = {
    skuSettings: SkuSettings[],
    error: string | null,
    loading: boolean
}

const initialState: SkuSettingsState = {
    skuSettings: [],
    error: null,
    loading: false
}

export const fetchSkuSettings = createAsyncThunk(
    'skuSettings/fetchSkuSettings',
    async () => {
        console.log('fetch')
        return (await getAllSkuSettings()).data;
    }
)

const skuSettingsSlice = createSlice({
    name: 'skuSettings',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchSkuSettings.fulfilled, (state, action) => {
            state.skuSettings = action.payload;
            state.error = null;
            state.loading = false;
        });

        builder.addCase(fetchSkuSettings.pending, (state) => {
            state.error = null;
            state.loading = true;
        });

        builder.addCase(fetchSkuSettings.rejected, (state, action) => {
            state.error = action.error.message ? action.error.message : 'Something went wrong';
            state.loading = false;
        });
    }
});

export default skuSettingsSlice.reducer;
