import { ComunicatorState, Message } from '../../types';
import { AppDispatch } from '../store';
import { v4 } from 'uuid';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState: ComunicatorState = {
    messages: []
}

const comunicatorSlice = createSlice({
    name: 'comunicator',
    initialState,
    reducers: {
        addMessage: (state, action: PayloadAction<Message>) => {
            state.messages = [...JSON.parse(JSON.stringify(state.messages)), action.payload];
        },
        removeMessageById: (state, action: PayloadAction<string>) => {
            state.messages = state.messages.filter(message => message.id !== action.payload);
        }
    }
})

const removeMessageAsync = createAsyncThunk(
    'messages/removeMessageAsync',
    async (messageInfo: MessageWithTimeout, {dispatch}) => {
        await new Promise((res) => setTimeout(res, messageInfo.removeDelay * 1000));
        dispatch(removeMessageById(messageInfo.messageId));
    }
)

interface MessageWithTimeout {
    messageId: string,
    removeDelay: number // seconds
}

export const addMessageWithTimeOut = (message: string, error: boolean) => (dispatch: AppDispatch) => {
    const removeDelaySec: number = 10;
    const messageObj: Message = {
        error,
        message,
        id: v4(),
        removeTimeDelay: removeDelaySec
    }
    dispatch(addMessage(messageObj));
    dispatch(removeMessageAsync({messageId: messageObj.id, removeDelay: removeDelaySec}))
}

export const {addMessage, removeMessageById} = comunicatorSlice.actions;

export default comunicatorSlice.reducer;
