import BRaportMarina from "../components/BRaportMarina";

const BaselinkerIntrastat = () => {
    return (
        <section className="h-full box-border">
            {/* <UnderHeader underheaderLinks={[{name: 'Baselinker Raport(Marina)', url: '/baselinker/bRaportMarina'}]}/> */}
            <BRaportMarina />
        </section>
    )
}

export default BaselinkerIntrastat;