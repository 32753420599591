import { FC, useEffect, useRef, useState } from "react";
import { RowProductData, checkIfRowShouldBeHiden, getSkuPrice } from "../../../../../../helpers/baselinkerCostReport.helper";
import costReportStyles from '../../styles.module.css';
import styles from './styles.module.css';
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { add, remove } from "../../../../../../store/features/costReportSlice";
import { v4 } from "uuid";
import { updateSkuSettings } from "../../../../../../api/backBaselinkerApi";
import { addMessageWithTimeOut } from "../../../../../../store/features/comunicatorSlice";
import { fetchSkuSettings } from "../../../../../../store/features/skuSettingsSlice";

type IProductRow = {
    productData: RowProductData,
    index: number,
    selectAllButton: boolean,
}

const ProductRow: FC<IProductRow> = ({productData, index, selectAllButton}) => {
    const {skuSettings} = useAppSelector((state) => state.skuSettings);

    const [price, setPrice] = useState(getSkuPrice(productData.sku, skuSettings));
    const [selector, setSelector] = useState(false);
    const [sku, setSku] = useState(productData.sku);

    const prevSkuState = useRef(skuSettings);
    const prevSelectAll = useRef(selectAllButton);

    const dispatch = useAppDispatch();


    useEffect(() => {
        console.log({current: prevSelectAll.current})
        console.log(selectAllButton)
        if(prevSelectAll.current !== selectAllButton) {
            console.log()
            onSelect(selectAllButton);
            prevSelectAll.current = selectAllButton;
        }

        if(prevSkuState.current !== skuSettings) {
            setPrice(getSkuPrice(productData.sku, skuSettings));
            prevSkuState.current = skuSettings;
        }
    }, [selectAllButton, skuSettings]);
    
    const onSelect = (status: boolean) => {
        if(status) {
            dispatch(add({
                id: productData.id, 
                currency: productData.currency, 
                name: productData.name,
                price: price,
                quantity: productData.quantity,
                sku: sku,
                countryCode: productData.countryCode
            }));
        } else {
            dispatch(remove(productData.id));
        }

        setSelector(status);
    }

    const onSavePrice = async () => {
        const response = await updateSkuSettings({
            price,
            sku
        })

        dispatch(fetchSkuSettings());

        dispatch(addMessageWithTimeOut(response.message, response.error));
    }
    
    return (
        <div  className={`
            ${index % 2 === 0 ? styles.darkRow: ''} 
            ${costReportStyles.row} ${costReportStyles.rowStructure} ${styles.body_row}`}
        >
            <input checked={selector} onChange={() => onSelect(!selector)} className={`${costReportStyles.checkbox} ${styles.checkboxStyling}`} type='checkbox' />
            <input value={sku} onChange={(e) => setSku(e.target.value)}  className={`${costReportStyles.sku} ${styles.skuStyling}`} />
            <div className={`${costReportStyles.price} ${styles.price_structure}`}>
                <input className={styles.price_style} type="number" value={price} onChange={e => setPrice(Number.parseFloat(e.target.value))}/>
            </div>
            <div  className={`${costReportStyles.quantity}`}>{productData.quantity}</div>
            <div  className={`${costReportStyles.productName}`}>{productData.name}</div>
            <div  className={`${costReportStyles.currency}`}>{productData.currency}</div>
            <div  className={`${costReportStyles.savePrice}`}>
                <button onClick={onSavePrice} className={`${costReportStyles.savePrice} ${styles.savePriceStyle}`}>Save Price</button>
            </div>
        </div>
    )
}

export default ProductRow;