import { Provider } from 'react-redux';
import {Header, Main, Footer} from '../components/index';
import store from '../store/store';

const App = () => {
    return (
        <Provider store={store}>
            <div className='flex flex-col'>
                <Header />
                <Main />
                <Footer />
            </div>
        </Provider>
    )
}

export default App;