import { PDFDownloadLink } from '@react-pdf/renderer';
import { BaselinkerSentReportType, GetSentReportResponseBodyDataContent, GetSentReportResponseBodyDataContentCountry, GetSentReportResponseBodyDataCurrencyContent } from '../../../../../types';
import styles from './styles.module.css';
import commonStyles from '../styles.module.css'
import PdfAccountentReport from './PdfAccountentReport';
import { useMemo, useState } from 'react';

export interface Report {
    sentType: BaselinkerSentReportType,
    report: GetSentReportResponseBodyDataContent,
    curDate: string
}

function getTotalSellPriceCountry(productsReport: GetSentReportResponseBodyDataCurrencyContent): number {
    return Object.values(productsReport).reduce((prev, cur) => prev + cur.totalSellPrice, 0);
}

const getTotalSellPerCurrency = (countryReport: GetSentReportResponseBodyDataContentCountry): number => {
    return Object.values(countryReport).reduce( (prev, cur) => prev + getTotalSellPriceCountry(cur), 0);
}

const AccountantReport: React.FC<Report> = ({report, sentType, curDate}) => {

    const genrateReportProducts = (productsReport: GetSentReportResponseBodyDataCurrencyContent, currency: string) => {
        return (
            <div className={styles["report-products"]}>
                <div className={`${styles["report-product"]} ${styles["report-product-filters"]}`}>
                    <span className={styles["report-product-id"]}>ID</span>
                    <span className={styles["report-product-id"]}>NAME</span>
                    <span className={styles["report-product-id"]}>SKU</span>
                    <span className={styles["report-product-id"]}>QUANTITY</span>
                    <span className={styles["report-product-id"]}>WAREHOUSE PRICE</span>
                    <span className={styles["report-product-id"]}>TOTAL SELL PRICE</span>
                </div>
                {Object.entries(productsReport).map( ([productId, productData], index) => (
                    <div key={index} className={styles["report-product"]}>
                        <span className={styles["report-product-id"]}>{productId}</span>
                        <span className={styles["report-product-id"]}>{productData.name}</span>
                        <span className={styles["report-product-id"]}>{productData.sku}</span>
                        <span className={styles["report-product-id"]}>{productData.quantity}</span>
                        <span className={styles["report-product-id"]}>{productData.warehousePrice} PLN</span>
                        <span className={styles["report-product-id"]}>{productData.totalSellPrice} {currency}</span>
                    </div>
                ))}
            </div>
        )
    }

    const Country = (productsReport: GetSentReportResponseBodyDataCurrencyContent, country: string, productIndex: number, currency: string) => {
        const totalSellPricePerCountry = getTotalSellPriceCountry(productsReport).toFixed(2);
        
        return (
            <div key={productIndex} className={styles["report-country"]}>
                <span className={styles["report-currency-title"]}>{country} ( Total: {totalSellPricePerCountry} {currency} )</span>
                <>
                    {genrateReportProducts(productsReport, currency)}
                </>
            </div>
        )
    }

    const Currency = (countryReport: GetSentReportResponseBodyDataContentCountry, currency: string, currencyIndex: number) => {
        const totalCurrency = getTotalSellPerCurrency(countryReport);
        
        return (
            <div key={currencyIndex} className={styles["report-currency"]}>
                <span className={styles["report-currency-title"]}>{currency} ( Total: { totalCurrency} {currency} )</span>
                    <div className={styles["report-countries"]}>
                        {Object.entries(countryReport).map(([country, data], countryIndex) => (
                            <>
                                {Country(data, country, countryIndex, currency)}
                            </>
                        ))}
                    </div>
            </div>
        )
    }
    
    return (
        <div className={styles["accountant-report"]}>
            <div className={commonStyles['report-type']}>
                <span className={styles["report-type"]}>Report type - Accountant {sentType.toUpperCase()}</span>
                <PDFDownloadLink className={commonStyles['report-type-download']} document={<PdfAccountentReport sentType={sentType} report={report}curDate={curDate} />} fileName={`${curDate}_${sentType}_accountant`}>
                    {({loading}) => (loading ? <button>Loading document...</button> : <button>Download</button>)}
                </PDFDownloadLink>
            </div>
                <div className={styles["report-currency-items"]}>
                    {Object.entries(report).map( ([currency, productsReport], index) => (
                        <>
                            {Currency(productsReport, currency, index)}
                        </>
                    ))}
                </div>
            </div>
    )
}

export default AccountantReport;