//-----Common-Start-----

import { RowProductData } from "./helpers/baselinkerCostReport.helper";

export const getFbRaportTypesArray = ['csv', 'api'] as const;
export type GetFbRaportType = typeof getFbRaportTypesArray[number];

export interface BackResponce<ResponseData> {
    error: boolean,
    message: string,
    data: ResponseData
}

export interface NewSheetData {
    requestData: Object,
    sheetName: string
}

export interface FetchFbRaportRequestBody {
    dateFrom: string, //
    dateTo: string,
    getFbRaportType: GetFbRaportType,
    file: File
}

export interface GetOrdersInputParams {
    dateFrom?: number,
    statusId?: number
}

export interface GetOrdersParams extends GetOrdersInputParams {
    dateTo?: number,
    statuses?: number[],
    severalFolder: boolean
}

export interface SendPaymentReportRequestBody {
    paymentType: PaymentRaportType
}

export type PaymentRaportType = 'stripe' | 'paypal' | 'klarna';

export interface PaymentBaselinkerReportParams {
    dateFrom: number,
    dateTo: number,
    newSheetName: string,
    correcting: boolean,
    dbName: string
}

export type PresentsBaselinkerReportParams = Omit<PaymentBaselinkerReportParams, "correcting">;

export interface CostsBaselinkerReportParams {
    dateFrom: number,
    dateTo: number
}


//-----Common-End-----

export interface IUnderHeader {
    underheaderLinks: UnderheaderLink[]
}

interface UnderheaderLink {
    name: string,
    url: string
}

export interface SameProductSerchingConfig {
    name: string,
    match: string[]
}

export interface FilterOptions {
    country: string,
    productName: string
}

export interface Message {
    id: string,
    removeTimeDelay: number,
    error: boolean,
    message: string
}

export interface ComunicatorState {
    messages: Message[]
}

export interface IOneOfRadio {
    elements: RadioElement<string>[],
    title?: string,
    type?: 'horizontal' | 'vertical',
    selectedValue: string,
    setSelectedValue: Function
}

export interface RadioElement<ValueType> {
    placeholder: string,
    value: ValueType
}

export interface SelectionYear {
    value: string | number,
    selected: boolean
}

interface CostsFilterParams {
    baseSkus: string[],
    price: number
}

export interface CostsFilterRules extends CostsFilterParams {
    secondLevel?: CostsFilterParams[]
}


export type BaselinkerSentReportType = 'sent' | 'basic return' | 'client return';
export type BaselinkerType = 'global' | 'robeauty';

export type BaselinkerStatus = {
    id: number,
    name: string,
    name_for_customer: string,
    color: string
}

export type BaseLoadData = {
    statuses: BaselinkerStatus[],
    inventories: BaselinkerInventory[]
}

export enum IskladType {
    EASYSHOP = 'easyshop',
    ROBEAUTY = 'robeauty'
}

export type IskladApiSettings = {
    id: number,
    type: IskladType,
    name: string
}

export type AddOrdersToIskaldReqBody = {
    iskladId: number
}

export type BaselinkerWarehouse = {
    warehouse_type: string, // Type: string
    warehouse_id: number, // Type: number
    name: string, // Type: string
    description: string, // Type: null (if it can be null)
    stock_edition: boolean, // Type: boolean
    is_default: boolean // Type: boolean
}

export type GetBaselinkerStatusesRequest = {
    baselinker: BaselinkerType
}

export type GetBaselinkerWarehousesRequest = {
    baselinker: BaselinkerType
}

export type GetBaselinkerSentReportRequest = {
    baselinker: BaselinkerType,
    selectedSrcStatus: BaselinkerStatus,
    sentType: BaselinkerSentReportType,
    selectedInventory: BaselinkerInventory,
    selectedDestNormalStatus: BaselinkerStatus,
    selectedDestCashStatus: BaselinkerStatus,
    selectedDestOnlineStatus: BaselinkerStatus,
    updateStatuses: boolean,
    products?: CombinedBaselinkerProductOrderId[]
}

export type GetBaselinkerOrdersRequest = {
    status: BaselinkerStatus,
    baselinker: BaselinkerType
}

export type BaselinkerInventory = {
    inventory_id: number,
    name: string,
}

export type GetSentReportResponseBodyData = {
    accounting: GetSentReportResponseBodyDataContent,
    warehouse: WarehouseReportData
}

export type WarehouseReportData = {
    orders: WarehouseSentReportData,
    products: WarehouseReportProducts
}

export type WarehouseReportProducts = {
    [id: string]: WarehouseReportProduct
}

export type WarehouseReportProduct = {
    name: string,
    sku: string,
    quanitity: number
}

export type WarehouseSentReportData = {
    [countryCode: string]: number[]
}

export type GetSentReportResponseBodyDataContent = {
    [currency: string]: GetSentReportResponseBodyDataContentCountry
}

export type GetSentReportResponseBodyDataContentCountry = {
    [countryCode: string]: GetSentReportResponseBodyDataCurrencyContent
}

export type GetSentReportResponseBodyDataCurrencyContent = {
    [productId: string]: {
        totalSellPrice: number,
        warehousePrice: number,
        sku: string,
        name: string,
        quantity: number
    }
}

export interface BaselinkerOrder {
    order_id: number,
    products: GetOrdersProduct[],
    delivery_country: string,
    delivery_price: number,
    order_source_id: number,
    order_source: string,
    date_add: number,
    currency: string,
    payment_done: number,
    delivery_country_code: string
}

export type CombinedBaselinkerProductOrderId = {
    order: CombinedBaselinkerOrder,
    product: Partial<GetOrdersProduct>
}

export type CombinedBaselinkerOrder = {
    orderId: number,
    currency: string,
    paied: boolean,
    countryCode: string
}

export interface BaselinkerProduct {
    storage: string,
    storage_id: number,
    order_product_id: number,
    sku: string,
    ean: string,
    variant_id: string,
    product_id: string,
    warehouse_id: number,
    quantity: number,
    price_brutto: number,
    order_status_id: number
}

export type GetOrdersProduct = BaselinkerProduct & {
    name: string
}

export type TransferBlOrdersToIskladResponse = {
    success: number[],
    unsuccess: number[]
}

export type SkuSettings = {
    id: number;
    sku: string;
    price: number;
    name: string;
}

export enum MatchType {
    EXACT_MATCH = 'exactMatch',
    PARTIAL_MATCH = 'partialMatch'
}

export type HideStatus = {
    hideForSku: boolean,
    hideForName: boolean
}

export type CostBaselinkerReportProduct = {
    sku: string,
    priceBrutto: number,
    name: string,
    quantity: number
}

export type CostBaselinkerReportOrder = {
    currency: string;
    countryCode: string;
    orderId: string,
    products: CostBaselinkerReportProduct[]
}

export type SendCostReportToGoogleReques = {
    tableName: string,
    data: CostReportFinalData[]
}

export type CostReportFinalData = {
    sku: string,
    price: number,
    quantity: number,
    name: string,
    currency: string,
    id: string,
    countryCode: string
}
