import { PDFDownloadLink } from '@react-pdf/renderer';
import { BaselinkerSentReportType, GetSentReportResponseBodyDataCurrencyContent, WarehouseReportData } from '../../../../../types';
import styles from './styles.module.css';
import PdfWarehouseReport from './PdfWarehouseReport';
import commonStyles from './../styles.module.css';
import { getOrdersTotal } from '../../../../../helpers/warehouse.helper';

export interface Report {
    sentType: BaselinkerSentReportType,
    report: WarehouseReportData,
    curDate: string
}

const WarehouseReport: React.FC<Report> = ({report, sentType, curDate}) => {

    const generateCountries = (report: WarehouseReportData) => {
        return(
            <div className={styles["warehouse-report-country-container"]}>
                {Object.entries(report.orders).map(([index, ordersIds]) => (
                    <div>{index} - {ordersIds.length}</div>
                ))}
            </div>
        )
    }
    
    return (
        <div className={styles["warehouse-report"]}>
            <div className={commonStyles['report-type']}>
                <span className={styles["warehouse-report-type-title"]}>Report type - Warehouse {sentType.toUpperCase()}</span>
                <PDFDownloadLink className={`${commonStyles['report-type-download']}`} document={<PdfWarehouseReport sentType={sentType} report={report} curDate={curDate} />} fileName={`${curDate}_${sentType}_warehouse`}>
                    {({loading}) => (loading ? <button>Loading document...</button> : <button>Download</button>)}
                </PDFDownloadLink>
            </div>
            <div className={styles["warehouse-report-country-items"]}>
                {generateCountries(report)}
            </div>
            <span>Total: {getOrdersTotal(report)}</span>
        </div>
    )
}

export default WarehouseReport;