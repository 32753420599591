import React from "react"

interface IBtn {
    value: string,
    className?: string,
    onClick?(e?: React.MouseEvent<HTMLInputElement>): void
}

const Btn: React.FC<IBtn> = ({value, className, onClick}) => {
    return (
        <div className="flex justify-center">
            <input onClick={onClick ? onClick : undefined} type="button" value={value} className={`${className ? className : ``} transition duration-300 text-white font-bold cursor-pointer bg-zinc-500 p-[10px] rounded-lg shadow-[0_0_10px_grey] hover:shadow-[0_0_10px_black]`}/>
        </div>
    )
}

export default Btn;