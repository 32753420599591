interface IDateInput {
    lableId: string
    lableText: string,
    setDateFunc: Function,
    value: string
}

const DateInput: React.FC<IDateInput> = ({setDateFunc, lableText, lableId, value}) => {

    const handleInputDate = (setDateFunc: Function) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            setDateFunc(event.target.value);
        }
    }

    return (
        <div className="flex flex-col items-center gap-[10px]">
            <label htmlFor={lableId}>{lableText}</label>
            <input id={lableId} value={value} type='date' className='' onChange={handleInputDate(setDateFunc)}/>
        </div>
    )
}

export default DateInput;