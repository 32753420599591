import { useEffect, useState } from 'react';
import Btn from '../../../components/Btns/Btn';
import styles from './styles.module.css';
import { transferOrdersToIsklad } from '../../../api/backAdminApi';
import { IskladApiSettings, IskladType, TransferBlOrdersToIskladResponse } from '../../../types';
import MultipleRadio from '../../../components/Radio/MultipleRadio'
import { v4 } from 'uuid';
import { getIskladSettings } from '../../../api/backIskladApi';
import { useAppDispatch } from '../../../store/hooks';
import { addMessageWithTimeOut } from '../../../store/features/comunicatorSlice';

const TransferToIsklad: React.FC<any> = () => {

    const dispatch = useAppDispatch();

    const [iskladApiSettings, setIskladApiSettings] = useState({
        loading: true,
        data: [] as IskladApiSettings[]
    });
    const [selectedIskladTypeId, setIskaladType] = useState<number | null>(null);

    const [transferOrdersState, setTransferOrdersState] = useState<{loading: boolean, data: TransferBlOrdersToIskladResponse}>({
        loading: false,
        data: {
            success: [],
            unsuccess: []
        }
    })

    const onTransferOrdersClick = async () => {
        if(selectedIskladTypeId) {
            setTransferOrdersState({...transferOrdersState, loading: true});
            const responseBody = await transferOrdersToIsklad({iskladId: selectedIskladTypeId as number});
            
            if(responseBody.data.success) {
                setTransferOrdersState({...transferOrdersState, loading: false, data: responseBody.data});
            } else {
                setTransferOrdersState({...transferOrdersState, loading: false});
            }

            dispatch(addMessageWithTimeOut(responseBody.message, responseBody.error));
            
        } else {
            dispatch(addMessageWithTimeOut(`Please select type!`, true));
        }
    }

    useEffect(() => {
        const fetchSettings = async () => {
            const resposeBody = await getIskladSettings();
    
            if(resposeBody.error === false) {
                setIskladApiSettings({...iskladApiSettings, loading: false, data: resposeBody.data});
                if(resposeBody.data.length > 0) setIskaladType(resposeBody.data[0].id)
            } else {
                dispatch(addMessageWithTimeOut(resposeBody.message, resposeBody.error));
            }

        }

        fetchSettings();
    }, [])
    
    return (
        <div className={`${styles.container}`}>
            {(iskladApiSettings.loading === false) ? 
            <div className={`${styles.nav}`}>
                <div className={`${styles.type}`}>
                    {iskladApiSettings.data.length > 0 ? 
                        <MultipleRadio uniqName={v4()} btns={iskladApiSettings.data.map((settings) => ({
                            title: settings.name,
                            uniqId: settings.id.toString(),
                            value: settings.id
                        }))} setValue={setIskaladType} selectedValue={selectedIskladTypeId} />
                    : <div>No settings in db!</div>}
                </div>
                <div className={styles.kiril}>
                    <Btn value='Send Orders To Isklad' onClick={onTransferOrdersClick}/>
                </div>

            </div> : <div>Loading...</div>}
            {transferOrdersState.loading && <span>"Loading..."</span>}
            {(transferOrdersState.data.success.length > 0 || transferOrdersState.data.unsuccess.length > 0) &&
            <>
                <div>
                    <div>Successed: {transferOrdersState.data.success.length}</div>
                    <div>Orders: {transferOrdersState.data.success.join(', ')}</div>
                </div>

                <div>
                <div>Unsuccessed: {transferOrdersState.data.unsuccess.length}</div>
                    <div>Orders: {transferOrdersState.data.unsuccess.join(', ')}</div>
                </div>
            </>
            }

        </div>
    )
}

export default TransferToIsklad