import { configureStore } from "@reduxjs/toolkit";
import comunicatorSlice from "./features/comunicatorSlice";
import skuSettingsSlice from "./features/skuSettingsSlice";
import costReportSlice from "./features/costReportSlice";
import dbDataReportSlice from "./features/dbDataReportSlice";
import costReportOriginSlice from "./features/costReportDbOriginDataSlice";

const store = configureStore({
    reducer: {
        comunicator: comunicatorSlice,
        skuSettings: skuSettingsSlice,
        costReport: costReportSlice,
        dbDataReport: dbDataReportSlice,
        costReportDbOrigin: costReportOriginSlice
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;