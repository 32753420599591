import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { Message } from "../../types";
import { removeMessageById } from "../../store/features/comunicatorSlice";

const Messages = () => {

    let messages = useAppSelector((state) => state.comunicator.messages);
    const dispatch = useAppDispatch();

    const onRemoveClick = (messageId: string) => () => {
        dispatch(removeMessageById(messageId));
    }

    const createMessages = (messages: Message[]):JSX.Element[] => {
        return messages.map((message) => (
            <div key={message.id} className={`opacity-[0.8] flex felx-row animate-hideSlide ${message.error ? 'bg-red-500' : 'bg-green-600'} gap-[10px] p-[10px] rounded-lg transition duration-300`}>
                <div onClick={onRemoveClick(message.id)} className="min-w-[20px] flex justify-center items-center cursor-pointer">
                    {message.error && (
                        <div className="h-[20px] border-r-2 rotate-45 border-white relative">
                            <div className=" absolute h-[20px] border-r-2 rotate-90 border-white"></div>
                        </div>
                    )}
                    {!message.error && (
                        <div className="w-[7px] h-[15px] border-b-2 border-r-2 rotate-45 border-white hover:scale-[1.2]"></div>
                    )}
                </div>
                <div className="text-white min-w-[200px] max-w-[400px] text-[15px] text">{message.message}</div>
            </div>
        ))
    }

    return (
        <section className="fixed bottom-0 right-1 flex flex-col gap-[5px] items-end">
            {createMessages(messages)}
        </section>
    )
}

export default Messages;