import React, { FC } from "react"

interface IInputText {
    value: string,
    setValue: Function,
    placeholder?: string,
    labelTitle?: string,
    className?: string,
}

const InputText:FC<IInputText> = ({value, setValue, placeholder, labelTitle, className}) => {
    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => setValue(e.target.value);

    return (
        <div className={`flex flex-col justify-center items-center ${className}`}>
            {labelTitle && <label>{labelTitle}</label>}
            <input onChange={onInputChange} value={value} placeholder={placeholder} type="text" className="border-black border-2 rounded-md w-[200px] focus:outline-none"/>
        </div>
    )
}

export default InputText;