import { v4 } from "uuid";
import { CostBaselinkerReportOrder, CostBaselinkerReportProduct, SkuSettings } from "../types"

export type RowProductData = CostBaselinkerReportProduct & Omit<CostBaselinkerReportOrder, 'products'> & {id: string};
export type SkuProductData = {
    [sku: string]: RowProductData
}


export type CountryReportData = {
    [countryCode: string]: SkuProductData;
}


export function createCountryData(orders: CostBaselinkerReportOrder[]): CountryReportData {
    const result: CountryReportData = {};
    for(let order of orders) {
        if(!result[order.countryCode]) result[order.countryCode] = {};
        const countryObj = result[order.countryCode];

        for(let product of order.products) {
            if(!countryObj[product.sku]) {
                countryObj[product.sku] = {
                    currency: order.currency,
                    name: product.name,
                    quantity: product.quantity,
                    countryCode: order.countryCode,
                    sku: product.sku,
                    orderId: order.orderId,
                    priceBrutto: product.priceBrutto,
                    id: v4()
                }
            } else {
                countryObj[product.sku].quantity += product.quantity;
                countryObj[product.sku].priceBrutto += product.priceBrutto;
            }

        }
        
    }

    return result;
}

export function getSkuPrice(sku: string, skusSettings: SkuSettings[]): number {
    const skuSetting = skusSettings.find( s => s.sku === sku);

    if(skuSetting) {
        return skuSetting.price;
    } else return 0;
}

export function checkIfRowShouldBeHiden(skusSettings: SkuSettings[], rowProductData:RowProductData) {
    const matchedSkuSettings = skusSettings.find( s => s.sku === rowProductData.sku && s.name === rowProductData.name);
    if(matchedSkuSettings) return true;
    return false;
}