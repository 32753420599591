import React from "react";
import Btn from "../Btns/Btn";

type FilesType = File | File[] | null;

interface IFileSelector {
    files: FilesType,
    setFiles: Function,
    placeHolder?: string,
    additionalButtons?: AdditionalButton[],
    multiple: boolean
}

interface AdditionalButton {
    value: string,
    onClick(): void
}

const FileSelector:React.FC<IFileSelector> = ({files, setFiles, placeHolder = "Press Here To Upload File", additionalButtons, multiple}) => {
    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files ? Array.from(e.target.files) : [];
        if(multiple) {
            setFiles(files);
        } else setFiles(files[0]);
    }

    const getFilesNames = (files: FilesType): string[] => {
        if(Array.isArray(files)) {
            return files.map( element => element.name);
        } else {
            return files ? [files.name]: [];
        }
    }

    const createFilesNames = (files: FilesType) => {
        return getFilesNames(files).map((fileName, index) => (
            <section>
                <span key={index} className="text-center mt-[100px] mb-[10px]">{fileName}</span>
            </section>
        ))
    }
    
    return (
        <div>
            <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col justify-center items-center">
                {createFilesNames(files)}
                <label  htmlFor="uploadFile" className={`${files && "shadow-[0_0_15px_red]"} border-dashed transition duration-300 font-bold hover: shad cursor-pointer p-[20px] border-[1px] border-black rounded-lg`}>{placeHolder}</label>
                <input value={''} id="uploadFile" className="hidden" type='file' multiple={multiple} onChange={handleFile}/>
                <div className="mt-2 flex flex-row gap-5">
                    <Btn value="Clear" onClick={() => setFiles(null)}/>
                    {additionalButtons?.map((btn, index) => <Btn key={index} value={btn.value} onClick={btn.onClick} />)}
                </div>
                </div>

            </div>
        </div>
    )
}

export default FileSelector;