import React from "react";
import { v4 } from "uuid";

interface CheckBox {
    labelName: string,
    value: boolean,
    setValue: Function
}

const CheckBox: React.FC<CheckBox> = ({value, labelName, setValue}) => {
    const id = v4();

    const onCheckboxChange = () => {
        setValue((prevState: any) => !prevState);
    }

    return (
        <div className="flex flex-row gap-[10px] items-center">
            <input checked={value} onChange={onCheckboxChange} id={id} type="checkbox"/>
            <label htmlFor={id}>{labelName}</label>
        </div>
    )
}

export default CheckBox;