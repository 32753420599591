import { useState } from "react";
import FileSelector from "../../components/FileSelectors/FileSelector";
import { useAppDispatch } from "../../store/hooks";
import { addMessageWithTimeOut } from "../../store/features/comunicatorSlice";
import { sendBaselinkerOrdersFilesToBack } from "../../api/backAdminApi";

const Admin = () => {
    const [files, setFiles] = useState<File[]>([]);
    const dispatch = useAppDispatch();

    const checkExtentions = (files: File[], allowedExtentions: string[]) => {
        for(let file of files) {
            if(!allowedExtentions.includes(file.type)) {
                return false;
            }
        }

        return true;
    }

    const onSendAction = async () => {
        const allowedExtentions = ['text/xml'];
        if(checkExtentions(files, allowedExtentions)) {
            const formData = new FormData();
            for(let file of files) {
                formData.append('file', file);
            }
            const response = await sendBaselinkerOrdersFilesToBack(formData);
            dispatch(addMessageWithTimeOut(response.message, response.error));
        } else {
            dispatch(addMessageWithTimeOut(`Only ${allowedExtentions.join(',')} file extention allowed!`, true));
        }
    }
    
    return (
        <section>
            <FileSelector multiple={true} files={files} setFiles={setFiles} additionalButtons={[{value: 'Send', onClick: onSendAction}]}/>
        </section>
    )
}

export default Admin;