import axios from "axios";
import { BackResponce, FetchFbRaportRequestBody, NewSheetData } from '../types';

const raportsUrl = 'api/v1/raports'
const baseURL = process.env.NODE_ENV === 'production' ? raportsUrl : `http://localhost:5000/${raportsUrl}`;

const backRaportsApi = axios.create({
    baseURL
});

export async function sentFbDataToBack(data: NewSheetData) {
    return (await backRaportsApi.post('/sendFBRaportDataToGS', data)).data
}

export async function fetchFbData(requestParams: FetchFbRaportRequestBody) {
    const formData = new FormData();
    for(let [key, value] of Object.entries(requestParams)) {
        formData.append(key, value);
    }
    return (await backRaportsApi.post('/collectFBData', requestParams)).data;
}

export async function sendPaymentRaportToBack(data: FormData): Promise<BackResponce<any>> {
    return (await backRaportsApi.post('/sendPaymentReportToGS', data)).data;
}