import { useState } from "react";
import { Link } from "react-router-dom";
import { sendIntrastatReport } from "../api/backBaselinkerApi";

const BRaportMarina = () => {
    let [file, setFile] = useState<File | null>();
    let [googleSheetLink, setGoogleSheetLink] = useState('');
    let [googleSheetName, setgoogleSheetName] = useState('');

    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if(files !== null && files.length > 0) {
            setFile(files[0]);
        }
    }

    const onSendButtonClick = async () => {
        console.log('Test 1')
        if(file && googleSheetName) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('sheetName', googleSheetName);
            const responceData = await sendIntrastatReport(formData);
            if(responceData) setGoogleSheetLink(responceData.data); // googleSheetLink
        }
    }

    return (
        <section>
            <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col justify-center items-center">
                <span className="text-center mt-[100px] mb-[10px]">{file && file.name}</span>
                    <label  htmlFor="uploadFile" className={`${file && "shadow-[0_0_15px_red]"} border-dashed transition duration-300 font-bold hover: shad cursor-pointer p-[20px] border-[1px] border-black rounded-lg`}>Press Here To Upload Raport File</label>
                    <input value={''} id="uploadFile" className="hidden" type='file' onChange={handleFile}/>
                    <input value={'Clear'} onClick={() => {setFile(null)}} className="mt-[10px] p-[10px] px-[20px] rounded-lg font-bold uppercase hover:bg-black hover:text-white transition duration-300 cursor-pointer border-[1px] border-black" type="button" />
                </div>
                {file && 
                    <div className="flex flex-row justify-center mt-[20px] gap-[10px] items-center flex-wrap">
                        <input className={`${googleSheetName.length > 0 && "shadow-[0_0_15px_red]"} focus:shadow-[0_0_15px_red] caret-transparent transition duration-300 rounded-lg focus:outline-none border-[1px] border-black p-[5px] text-center font-bold`} onChange={(e) => setgoogleSheetName(e.target.value)} value={googleSheetName} placeholder="Input G-Sheet Name" type="text"/>
                        <input className={`${googleSheetName.length > 0 && "transition duration-300 shadow-[0_0_10px_red] cursor-pointer"} font-bold text-[15px] p-[15px] rounded-lg border-[1px] border-black text-black`} onClick={onSendButtonClick} type="button" value='Send file to G-Sheet' />
                    </div>}
                    <Link className="font-bold color text-blue-500 underline" to={googleSheetLink}>G-Sheet</Link>
            </div>
        </section>
    )
}

export default BRaportMarina;