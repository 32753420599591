import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { CostBaselinkerReportOrder, CostsBaselinkerReportParams } from '../../types';
import { getCostsBaselinkerData }  from './../../api/backBaselinkerApi';
import {update} from '../features/dbDataReportSlice'
import { createCountryData } from '../../helpers/baselinkerCostReport.helper';
import {useAppDispatch} from '../hooks';

type CostReportOriginDataState = {
    data: CostBaselinkerReportOrder[],
    error: string | null,
    loading: boolean
}

const initialState: CostReportOriginDataState = {
    data: [],
    error: null,
    loading: false
}


export const fetchOroginBaselinkerData = createAsyncThunk(
    'costReportDbOrigin/fetchSkuSettings',
    async (data: CostsBaselinkerReportParams) => {
        const dataResponse =  (await getCostsBaselinkerData(data)).data;

        return dataResponse;
    }
)

const skuSettingsSlice = createSlice({
    name: 'costReportDbOrigin',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchOroginBaselinkerData.fulfilled, (state, action) => {
            state.data = action.payload;
            state.error = null;
            state.loading = false;
        });

        builder.addCase(fetchOroginBaselinkerData.pending, (state) => {
            state.error = null;
            state.loading = true;
        });

        builder.addCase(fetchOroginBaselinkerData.rejected, (state, action) => {
            state.error = action.error.message ? action.error.message : 'Something went wrong';
            state.loading = false;
        });
    }
});

export default skuSettingsSlice.reducer;
