import { useEffect } from "react";
import { SkuProductData } from "../../../../../helpers/baselinkerCostReport.helper";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import costReportStyles from '../styles.module.css';
import ProductRow from "./ProductRow";
import styles from './styles.module.css';
import { fetchSkuSettings } from "../../../../../store/features/skuSettingsSlice";

type IBody = {
    selectAllButton: boolean
}

const Body: React.FC<IBody> = ({selectAllButton}) => {

    const {productsData} = useAppSelector((state) => state.dbDataReport)

    const dispatch = useAppDispatch();

    const generateReportBySku = (skuProductsData: SkuProductData) => {
        return Object.entries(skuProductsData).map( 
            ([sku, rowData], index) => {
            return<ProductRow index={index} productData={rowData} selectAllButton={selectAllButton}/>
        })
    }

    const generateReportByCountry = () => {
        return Object.entries(productsData).map(([countryCode, skusData]) => (
            <div>
                <div className={`${costReportStyles.row} ${styles.contry}`}>{countryCode}</div>
                {generateReportBySku(skusData)}
            </div>
        ))
    }

    useEffect(() => {
        dispatch(fetchSkuSettings());
    }, [productsData])

    return (
        <div>
            {generateReportByCountry()}
        </div>
    )
}

export default Body;