import React from "react";

interface Option {
    value: string,
    content: string,
    disabled?: boolean,
    classNames?: string[],
    style?: React.CSSProperties
}

const Option: React.FC<Option> = ({content, value, disabled=false, classNames, style}) => {
    return (
        <option style={style} value={value} disabled={disabled}>
            {content}
        </option>
    )
}

export default Option;