import { useEffect, useState } from "react";
import { DateFromToInput } from "../../components";
import { createPaymentBaselinkerRaport, getAllDbNames } from "../../api/backBaselinkerApi";
import DateTime from "../../helpers/DateTime";
import InputText from "../../components/InputText/InputText";
import Btn from "../../components/Btns/Btn";
import { useAppDispatch } from "../../store/hooks";
import { addMessageWithTimeOut } from "../../store/features/comunicatorSlice";
import CheckBox from "../../components/CheckBoxs/CheckBox";
import OneOfRadio from "../../components/Radio/OneOfRadio";

const BaselinkerPaymentRaport = () => {
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [newSheetName, setNewSheetName] = useState('');
    const [includeCorrecting, setIncludeCorrecting] = useState(false);
    const [dbName, setDbName] = useState<string>('');
    const [dbNames, setDbNames] = useState<string[]>([]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        getAllDbNames()
        .then( data => {
            setDbNames(data.data);
            setDbName(data.data.length > 0 ? data.data[0] : '');
        })
        .catch(() => {
            dispatch(addMessageWithTimeOut('Server error when trying to load dbs names', true));
        })
    },[])

    const onCreatePaymentReport = async () => {
        if(dateFrom && dateTo && newSheetName && dbName) {
            try {
                const responseData = await createPaymentBaselinkerRaport({
                    dateFrom: DateTime.timeMsToDayMs(Date.parse(dateFrom)) - 60 * 60 * 2,
                    dateTo: DateTime.addDaysToUnix(DateTime.timeMsToDayMs(Date.parse(dateTo)), 1) - 60 * 60 * 2,
                    newSheetName,
                    correcting: includeCorrecting,
                    dbName
                })
                dispatch(addMessageWithTimeOut(responseData.message, responseData.error));
            } catch {
                dispatch(addMessageWithTimeOut('Server error', true));
            }
        } else {
            dispatch(addMessageWithTimeOut('Please fill each field', true));
        }
    }

    return (
        <>
            {dbNames.length > 0 && 
                <div className="flex flex-col items-center w-[100%] gap-[10px]">
                    <DateFromToInput dateFrom={dateFrom} dateTo={dateTo} setDateFrom={setDateFrom} setDateTo={setDateTo} />
                    <OneOfRadio elements={dbNames.map( dbName => ({placeholder: dbName, value: dbName}))} title="Select DB" selectedValue={dbName} setSelectedValue={setDbName} />
                    <div className="flex flex-row gap-[10px]">
                        <CheckBox value={includeCorrecting} setValue={setIncludeCorrecting} labelName="Include correcting?"/>
                        <InputText setValue={setNewSheetName} value={newSheetName} placeholder='Input new sheet name'/>
                        <Btn onClick={onCreatePaymentReport} value="Generate report"/>
                    </div>
                    <a target="_blank" href="https://docs.google.com/spreadsheets/d/17rznsoGaXA2UcGN6a1pGr2c3WiiSJtMsgpy2VMHvPRU/edit?usp=sharing" className="font-bold underline decoration-black">Google Sheet Link</a>
                </div>
            }
        </>
    )
}

export default BaselinkerPaymentRaport;