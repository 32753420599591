import { NavLink } from "react-router-dom";

const Header = () => {
    const links = [
        {name: "Facebook", link: '/facebook'},
        {name: "Baselinker Intrastat", link: '/baselinkerIntrastat'},
        {name: 'Baselinker Orders Statistics', link: '/baselinkerOrdersStatistics'},
        {name: 'Payments Reporst', link: '/paymentReports'},
        {name: 'Baselinker', link: '/baselinker'},
    ]
    const baseNavClasses = "bg-zinc-500 p-[20px] rounded-lg shadow-[0_0_10px_black] text-white font-bold transition duration-600";
    const activeClasses = "shadow-[0_0_10px_red] bg-red-400";
    return (
        <header className="w-full h-[10vh]">
            <nav className="w-full h-full">
                <ul className="flex flex-row h-full items-center mx-[15px] flex-wrap gap-[20px]">
                    {links.map( (value, index) => <li key={index} className='flex'><NavLink className={({isActive}) => `${baseNavClasses} ${isActive && activeClasses}`} to={value.link}>{value.name}</NavLink></li>)}
                </ul>
            </nav>
        </header>
    )
}

export default Header;