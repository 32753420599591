import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Facebook from './Facebook';
import BaselinkerIntrastat from './BaselinkerIntrastat';
import BaselinkerOrdersStatictics from './BaselinkerOrdersStatistics';
import Baselinker from './Baselinker';
import Admin from './Admin';
import BaselinkerPaymentRaport from './Baselinker/BaselinkerPaymentRaport';
import BaselinkerCostsReport from './Baselinker/BaselinkerCostReport';
import BaselinkerSentReport from './Baselinker/BaselinkerSentReport/BaselinkerSentReport';
import AccountantReport from './Baselinker/BaselinkerSentReport/Report/Accountent/AccountantReport';
import TransferToIsklad from './Baselinker/BaselinkerTransferToIsklad';

const Main = () => {
    return (
        <main className='w-full min-h-[80vh] box-border relative'>
            <Routes>
                <Route path='/' element={<Navigate to='baselinker'/>} />
                <Route path='facebook/*' element={<Facebook />} />
                <Route path='baselinkerIntrastat/*' element={<BaselinkerIntrastat />} />
                <Route path='baselinkerOrdersStatistics' element={<BaselinkerOrdersStatictics />}/>
                <Route path='baselinker' element={<Baselinker />}>
                    <Route path='/baselinker' element={<Navigate to='get-sent-report'/>} />
                    <Route path='payment-report' element={<BaselinkerPaymentRaport />} />
                    <Route path='get-product-costs' element={<BaselinkerCostsReport />} />
                    <Route path='transfer-orders-to-isklad' element={<TransferToIsklad />} />
                    <Route path='get-sent-report/*' element={<BaselinkerSentReport />}/>
                </Route>
                <Route path='/admin' element={<Admin />} />
            </Routes>
        </main>
    )
}

export default Main;