import { FC } from "react"
import AdInfo from "./AdInfo"

interface IAdsInfo {
    filteredFbRaports: any[]
}

const AdsInfo: React.FC<IAdsInfo> = ({filteredFbRaports}) => {
    const adsInfo = (): JSX.Element[] => filteredFbRaports.map( (filteredFbRaport: any[], index: number) => {
        const accountNumber = filteredFbRaport[0].account_id

        return (<AdInfo key={index} accountNumber={accountNumber} campaignsQuantity={filteredFbRaport.length}/>)
    })

    return (
        <section className="grid grid-cols-3 mx-[10px] gap-[10px] h-[50%] overflow-scroll no-scrollbar items-start">
            {adsInfo()}
        </section>
    )
}

export default AdsInfo;