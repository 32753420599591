import { useState } from 'react';
import { AdsInfo, DateInput, Option } from '../components';
import { fetchFbData, sentFbDataToBack } from '../api/backRaportsApi';
import { collectCountriesNames, collectProductNames, filterFbData } from '../helpers/fbDataHelper';
import { FilterOptions, GetFbRaportType, getFbRaportTypesArray } from '../types';
import { useAppDispatch } from '../store/hooks';
import { addMessageWithTimeOut } from '../store/features/comunicatorSlice';
import OneOfRadio from '../components/Radio/OneOfRadio';
import FileSelector from '../components/FileSelectors/FileSelector';

const Facebook = () => {
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [raports, setRaports] = useState<any[]>([]);
    const [filteredRaports, setfilteredRaports] = useState<any[]>([]);
    const [showPopUpGS, setShowPopUpGS] = useState(false); // GS - Google Sheet
    const [tableName, setTableName] = useState<string>("");
    const [availableCountries, setAvailableCountries] = useState<string[]>([]);
    const [availableProducts, setAvailableProducts] = useState<string[]>([]);
    const [filterOptions, setFilterOptions] = useState<FilterOptions>({country: '', productName: ''});
    const [getFbRaportType, setGetFbRaportType] = useState<GetFbRaportType>('api');
    const [adsFile, setAdsFile] = useState<File | null>(null);

    const dispatch = useAppDispatch();

    const showDownloadBtn = (dateFrom: string, dateTo: string, adsFile: File | null): boolean => {
        if((dateFrom && dateTo) || adsFile) return true;
        return false;
    }

    const onFilterClick = () => {
      setfilteredRaports(filterFbData(raports, filterOptions));
    }

    const onDRButtonClick = async () => { // DRB - Download Raports
      if(dateFrom && dateTo && getFbRaportType && adsFile) {
        const backResponse = (await fetchFbData({dateFrom, dateTo, getFbRaportType, file: adsFile}));
        if(backResponse.error === false) {
          setAvailableCountries(collectCountriesNames(backResponse.data));
          setAvailableProducts(collectProductNames(backResponse.data));
          setfilteredRaports(backResponse.data);
          setRaports(backResponse.data);
        }
        dispatch(addMessageWithTimeOut(backResponse.message, backResponse.status === 'error' ? true : false))
      } else dispatch(addMessageWithTimeOut('Please fill all fieslds', true));
    }

    const onSendDataClick = async () => {
        const responceData = await sentFbDataToBack({
            requestData: filteredRaports,
            sheetName: tableName
        });
    }

    const onChangeInputSheetName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTableName(e.target.value);
    }

    return (
      <div className="w-full h-full flex flex-col gap-[20px]">
        {<OneOfRadio  
          selectedValue={getFbRaportType}
          setSelectedValue={setGetFbRaportType}
          title='Select report type'
          elements={getFbRaportTypesArray.map((option) => ({placeholder: option, value: option}))} />
        }
        {getFbRaportType === 'api' && 
          <div>
            <div className="flex justify-center gap-[20vw]">
              <DateInput
                lableText="Set date from"
                setDateFunc={setDateFrom}
                lableId="date_input_1"
                value={dateFrom}
              />
              <DateInput
                lableText="Set date to"
                setDateFunc={setDateTo}
                lableId="date_input_2"
                value={dateTo}
              />
            </div>
          </div>
        }
        {getFbRaportType === 'csv' && <FileSelector 
          files={adsFile} setFiles={setAdsFile} multiple={false} placeHolder='Select CSV File'
        />}
        {showDownloadBtn(dateFrom, dateTo, adsFile) && (
          <div className="flex justify-center gap-[10px]">
            <input
              onClick={onDRButtonClick}
              type="button"
              className="ease-in-out duration-150 font-bold p-[20px] rounded-lg cursor-pointer bg-gray-200 shadow-gray-500/30 shadow-lg hover:shadow-gray-500/50 translate"
              value="Load Facebook Raports"
            />
            {raports.length > 0 && (
              <input
                onClick={() => setShowPopUpGS(true)}
                type="button"
                className="ease-in-out duration-150 font-bold p-[20px] rounded-lg cursor-pointer bg-gray-200 shadow-gray-500/30 shadow-lg hover:shadow-gray-500/50 translate"
                value="Send To G-Sheet"
              />
            )}
          </div>
        )}

        {showPopUpGS && (
          <section className="flex justify-center items-center top-0 left-0 fixed w-[100vw] h-[100vh]">
            <div
              onClick={() => setShowPopUpGS(false)}
              className="z-10 top-0 left-0 fixed w-[100vw] h-[100vh] bg-slate-200 opacity-80"
            ></div>
            <div className="w-[50vw] h-[80vh] bg-slate-50 shadow-[0_0_20px_grey] z-20 rounded-lg flex flex-col">
              <div className="w-full h-[20%] text-center font-bold text-[25px]">Create Google Sheet</div>
              <div className='flex flex-row flex-wrap justify-center gap-[20px]'>
                <span>From: {dateFrom}</span>
                <div className='h-[2px] self-center w-[30px] border-solid border-b-[2px] border-black relative'>
                  <div className='absolute w-[10px] h-[10px] border-t-[2px] border-r-[2px] border-black right-0 rotate-45 translate-y-[-50%]'></div>
                </div>
                <span>To: {dateTo}</span>
              </div>
              <section className='flex flex-row place-content-between mx-[10px] flex-wrap items-center'>
                <div>
                    <label htmlFor='selectCountry'>Select Country</label>
                    <select id='selectCountry' defaultValue={''} onChange={(e) => setFilterOptions({...filterOptions, country: e.target.value})}>
                        <option></option>
                        {availableCountries.map((value: string, index: number) => (
                            <Option content={value} value={value} key={index}/>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor='selectProduct'>Select Product</label>
                    <select id='selectProduct' defaultValue={''} onChange={(e) => setFilterOptions({...filterOptions, productName: e.target.value})}>
                    <option></option>
                        {availableProducts.map((value: string, index: number) => (
                            <Option content={value} value={value} key={index}/>
                        ))}
                    </select>
                </div>
                <div>
                  <input onClick={onFilterClick} type='button' value='Filter' className='p-[10px] bg-slate-600 rounded-lg text-white transition duration-300 hover:shadow-[0_0_10px_grey] cursor-pointer'/>
                </div>
                <div>
                </div>
              </section>
              <AdsInfo filteredFbRaports={filteredRaports}/>
              <div className="w-full flex flex-col justify-center items-center">
                <label htmlFor="inputSheetName">Input Sheet Name</label>
                <input
                  onChange={onChangeInputSheetName}
                  id="inputSheetName"
                  type="text"
                  className="border-2 border-slate-600 rounded-md w-[30%] text-center"
                />
              </div>
              <div className="w-full flex justify-center items-center">
                <input
                  onClick={onSendDataClick}
                  type="button"
                  value="Send"
                  className="p-[20px] bg-slate-600 rounded-lg text-white transition duration-300 hover:shadow-[0_0_10px_grey] cursor-pointer"
                />
              </div>
              <div className='flex flex-row justify-start ml-[10px]'>
                <a target='_blank' href='https://docs.google.com/spreadsheets/d/1E73-u2PcwAWXIgkTxgb2j0b0C5nHamgMIaLtiiG-MoM/edit#gid=741134364'
                  className='bg-black max-w-fit text-white p-[10px] rounded-lg'
                >
                  Go To Google Sheet
                </a>
              </div>
            </div>
          </section>
        )}
      </div>
    );
}

export default Facebook;