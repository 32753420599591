import { FilterOptions, SameProductSerchingConfig } from "../types";

export function collectCountriesNames(fbRaports: any[]): string[] {
    let countryNames: string[] = [];
    for(let elementById of fbRaports) {
        for(let campaign of elementById) {
            const country = campaign.country;
            if(country) {
                if(!countryNames.includes(country)) {
                    countryNames.push(country);
                }
            }
        }
    }
    return countryNames;
}

export function collectProductNames(fbRaports: any[]): string[] {
    setProducts(fbRaports);
    let productNames: string[] = [];
    for(let elementById of fbRaports) {
        for(let campaign of elementById) {
            const product = campaign.productName;
            if(product) {
                if(!productNames.includes(product)) {
                    productNames.push(product);
                }
            }
        }
    }
    return productNames;
}

function setProducts(fbRaports: any[]) {
    const matchOptions: SameProductSerchingConfig[] = [
        {name: "szklarnia", match: ['Tun-', 'Tunel']},
        {name: "easyheater", match: ['EasyHeater', 'Harmony', 'Easy Heater']},
        {name: "easygrass", match: ['EasyGrass']},
        {name: "hamak", match: ['Hamak']},
        {name: 'airsofa', match: ['Sofa']},
        {name: 'siewnik', match: ['Siewnik']},
        {name: 'trawnik', match: ['trawnik']}
    ]

    for(let elementById of fbRaports) {
        for(let campaign of elementById) {
            let productWasFound = false;
            campaign.productName = '';
            for(let matchOption of matchOptions) {
                const campaignName = campaign.campaign_name?.toLowerCase() as string;
                if(campaignName) {
                    for(let match of matchOption.match) {
                        if(campaignName.includes(match.toLowerCase())) {
                            campaign.productName = matchOption.name;
                            productWasFound = true;
                            break;
                        }
                    }
                } else {
                    throw new Error('Campaign name column using but does not exist.');
                }

                if(productWasFound) break;
            }
        }
    }
}

export function filterFbData(fbRaports: any[], filterOptions: FilterOptions): any[] {
    let containOptions = false;
    for(let value of Object.values(filterOptions)) {
        if(value) {
            containOptions = true;
            break;
        }
    }

    if(!containOptions) return fbRaports;

    let sortedFbRaports: any[] = [];

    for(let elementById of fbRaports) {
        sortedFbRaports.push([]);
        let sortedCampaign = sortedFbRaports[sortedFbRaports.length - 1];
        for(let campaign of elementById) {
            let matchCount = 0;
            for(let key of Object.keys(filterOptions)) {
                if(campaign[key] === (filterOptions as any)[key] || (filterOptions as any)[key] === '') {
                    matchCount++;
                }
            }
            if(Object.keys(filterOptions).length === matchCount) sortedCampaign.push({...campaign});
        }

        if(sortedCampaign.length === 0) sortedFbRaports.pop();
    }

    return sortedFbRaports;
}

