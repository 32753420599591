import { FC, useEffect } from "react"
import { v4 } from "uuid"
import { IOneOfRadio, RadioElement } from "../../types";

const OneOfRadio: FC<IOneOfRadio> = ({elements, type = 'horizontal', title, selectedValue, setSelectedValue}) => {

    useEffect(() => {
        


    }, [])

    const generateRadios = (elements: RadioElement<string>[]): JSX.Element[] => {
        const commonName = v4();

        return elements.map((element, index) => {

            const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedValue(e.target.value);
            };
            const commonForId = v4();
            return (
                <div className="flex" key={index}>
                    <label htmlFor={commonForId} className={`cursor-pointer p-[5px] rounded-lg transition duration-200 hover:border-opacity-100 ${selectedValue === element.value ? 'shadow-[0_0_10px_red] bg-red-600 font-bold text-white': undefined}`}>{element.placeholder}</label>
                    <input value={element.value} onChange={onRadioChange} defaultChecked={element.value === selectedValue ? true: false} id={commonForId} type="radio" name={commonName} hidden/>
                </div>
            )
        })
    }

    return (
        <div className="flex flex-col justify-center items-center">
            <h3 className="font-bold text-[20px]">{title}</h3>
            <div className={`flex justify-center items-center gap-[100px] ${type === 'horizontal' ? 'flex-row' : 'flex-col'} ${elements.length === 0 && 'hidden'}`}>
                {generateRadios(elements)}
            </div>
        </div>
    )
}

export default OneOfRadio;