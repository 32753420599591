import { FC, useEffect, useState } from "react";
import { CostBaselinkerReportOrder } from "../../../../types";
import Body from "./Body";
import styles from './styles.module.css';
import { createCountryData } from "../../../../helpers/baselinkerCostReport.helper";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { addMessageWithTimeOut } from "../../../../store/features/comunicatorSlice";
import { sendReportDataToGoogle } from "../../../../api/backBaselinkerApi";
import { update } from "../../../../store/features/dbDataReportSlice";

const CostReport = () => {

    const dispatch = useAppDispatch();
    const [selectAll, setSelectAll] = useState(false);
    const [tableName, setTableName] = useState('');

    const {productsData} = useAppSelector((store) => store.costReport);
    const {data} = useAppSelector((store) => store.costReportDbOrigin);

    const ifBasicDataExist = () => {
        return data && data.length > 0 ? true: false;
    }

    const onSendToGoogleSheet = async () => {
        const response = await sendReportDataToGoogle({
            data: productsData,
            tableName
        })
        dispatch(addMessageWithTimeOut(response.message, response.error));
    }

    useEffect(() => {
        const generateCountryData = createCountryData(data);
        dispatch(update(generateCountryData));
    }, [data])

    return (
        <>
            {ifBasicDataExist() && 
                <section className={styles.content}>
                    <nav className={`${styles.row} ${styles.navigation}`}>
                        <div className={`${styles.navLeft}`}>
                            <button className={`${styles.selectAll}`} onClick={() => setSelectAll(!selectAll)}>All</button>
                            <div>
                                Selected {productsData.length}
                            </div>
                        </div>
                        <div className={`${styles.sendContainer}`}>
                            <input value={tableName} onChange={(e) => setTableName(e.target.value)} className={`${styles.tableName}`} type="text" />
                            <button onClick={onSendToGoogleSheet} className={`${styles.send}`}>Send To Google Sheet</button>
                        </div>
                        <div className={`${styles.totalOrders}`}>
                            <div>Total Orders: {data?.length}</div>
                        </div>
                    </nav>
                    <div className={`${styles.row} ${styles.rowStructure} ${styles.head}`}>
                        <div className={styles.checkbox}></div>
                        <div className={styles.sku}>Sku</div>
                        <div className={styles.price}>Price brutto</div>
                        <div className={styles.quantity}>Quantity</div>
                        <div className={styles.productName}>Product name</div>
                        <div className={styles.currency}>Currency</div>
                        <div className={styles.savePrice}>Save price for sku</div>
                    </div>
                    <Body selectAllButton={selectAll}/>
                </section>
            }
        </>
    )
}

export default CostReport;