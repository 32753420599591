import axios from "axios";
import { BackResponce, CostBaselinkerReportOrder, CostsBaselinkerReportParams, GetOrdersParams, PaymentBaselinkerReportParams, PresentsBaselinkerReportParams, SendCostReportToGoogleReques, SkuSettings } from "../types";

const baselinkersUrl = 'admin/api/v1/baselinker';
const baseURL = process.env.NODE_ENV === 'production' ? `/${baselinkersUrl}` : `http://localhost:5002/${baselinkersUrl}`;

const api = axios.create({baseURL: baseURL});

export async function fetchBaselinkerOrders(data: GetOrdersParams): Promise<BackResponce<any[]>> {
    return (await api.post('/fetchOrders', data)).data;
}

export async function createPaymentBaselinkerRaport(data: PaymentBaselinkerReportParams): Promise<BackResponce<any[]>> {
    return (await api.post('/createPaymentBaselinkerRaport', data)).data;
}

export async function createPresentsBaselinkerRaport(data: PresentsBaselinkerReportParams): Promise<BackResponce<any[]>> {
    return (await api.post('/createPresentsBaselinkerRaport', data)).data;
}

export async function getCostsBaselinkerData(data: CostsBaselinkerReportParams): Promise<BackResponce<CostBaselinkerReportOrder[]>> {
    return (await api.post('/get/baselinker/costs/report/data', data)).data;
}

export async function getAllDbNames(): Promise<BackResponce<string[]>> {
    return (await api.get('/get/db/names')).data;
}

export async function getAllSkuSettings(): Promise<BackResponce<SkuSettings[]>> {
    return (await api.get('/get/sku/settings')).data;
}

export async function updateSkuSettings(body: Partial<SkuSettings>): Promise<BackResponce<string[]>> {
    return (await api.put('/update/sku/settings', body)).data;
}

export async function removeSkuSettings(body: Partial<SkuSettings>): Promise<BackResponce<any>> {
    return (await api.post('/remove/sku/settings', body)).data;
}

export async function sendReportDataToGoogle(body: SendCostReportToGoogleReques): Promise<BackResponce<any>> {
    return (await api.post('/cost-report/send/gooogle', body)).data;
}

export async function sendIntrastatReport(data: FormData): Promise<BackResponce<any>> {
    console.log('Test')
    return (await api.post('/send/intrastat/report', data)).data;
}
