import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import { CountryReportData } from '../../helpers/baselinkerCostReport.helper';

type DbReportDataState = {
    productsData: CountryReportData
}

const initialState: DbReportDataState = {
    productsData: {}
}

const costReportSlice = createSlice({
    name: 'dbCostData',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<CountryReportData>) => {
            state.productsData = action.payload;
        }
    }
});

export const {update} = costReportSlice.actions;

export default costReportSlice.reducer;
